import styled from "styled-components";
import { mobile } from "../utils/responsive";

const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  border: ${(props) => `3px solid ${props.color}`};
  ${mobile({ padding: "20px" })}
`;

const Message = styled.h1`
  font-weight: 500;
  ${mobile({ fontSize: "16px" })}
`;

const SuccessMessage = ({ message, color }) => {
  return (
    <Container color={color}>
      <Message>{message}</Message>
    </Container>
  );
};

export default SuccessMessage;
